//positioning
.bl-introtext {
    @include breakpoint(large) {
        .posgrid {
            @include grid12;
        }
        .ctrbreadcrumb {
            grid-column: 1 / span 12;
        }
        .ctrtitle {
            grid-column: 1 / span 5;
        }
        .ctrtext {
            grid-column: 7 / span 6;
        }
    }
    @include breakpoint(medium down) {
        .leadtext {
            margin-top:1em;
        }
    }
}

//styling
.bl-introtext {
    .ctrbreadcrumb {
        margin-bottom:2em;
    }
    .breadcrumb {
        @include reset-list;
        display:flex;
        flex-wrap: wrap;
        $breadcrumbGray:#7F7F7F;
        color:$breadcrumbGray;
        li {
            padding-right:0.4em;
        }
        li + li::before {
            content:"•";
            padding-right:0.4em;
            color:$breadcrumbGray;
        }
        li:last-child {
            color:$black;
        }
    }
    h2, .downloadlist {
        .ctx-schaerme & {
            color:$ras-brown;
        }
        .ctx-hotel & {
            color: $kh-blue;
        }
    }
}

//typo
.bl-introtext {
    h1 {
        margin-bottom: 0;
        max-width:6em;
        .ctx-schaerme & {
            color:$ras-brown;
        }
        .ctx-hotel & {
            color: $kh-blue;
        }
    }
    .leadtext {
        @include typo-lead;
    }
    .breadcrumb {
        @include typo-breadcrumb;
    }
}