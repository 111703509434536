.bl-kontaktformular {
    @include defaultsectionpadding;
}

//typo
.bl-kontaktformular {
    .subtitle {
        @include typo-h3;
        text-transform: none;
    }

    h2 {
        margin-bottom:0.3em;
        @include typo-h1;
        text-transform: none;
    }
    .subtitle {
        margin-bottom:1em;
    }
    h2, .subtitle {
        color:$ras-red;
    }
}